import {
  Button,
  FilterInput,
  Icon,
  Snackbar,
  styled,
} from 'shamrock-clover-ui';
import {
  TextContainer,
  ViewText,
  CapacitySearchContainer,
  Container,
} from 'features/carrierCapacity/CarrierCapacityStyles';
import { Flex } from 'components/Flex';
import { useState } from 'react';
import CarrierSearchResults from './components/CarrierSearchResults';
import { resultsState } from './CapacityTypes';

const ToolbarContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  height: 40px;
  align-items: center;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 8px 16px;
  gap: 24px;
`;

const StyledInput = styled(FilterInput)`
  border: none;
  font-family: Proxima Nova;
  font-size: ${({ theme }) => theme.font.size.xsmall};
  color: #333333;
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray[40]};
    font: 14px;
    font-weight: 400;
  }
`;

const Carriers = () => {
  const [value, setValue] = useState('');
  const MIN_SEARCH_LENGTH = 3;
  const isDisabled = value.length < MIN_SEARCH_LENGTH;

  const showSnackbar = value.length > 0 && value.length < MIN_SEARCH_LENGTH;

  const [resultsState, setResultsState] = useState<resultsState>('initialView');

  const clearValue = () => {
    setValue('');
    setResultsState('initialView');
  };

  const onClick = () => {
    // TODO: onClick setResultsState to 'isLoading'
    // after the request is successful, setResultsState to 'resultsAvailable'
    setResultsState('resultsAvailable');
  };
  return (
    <Container>
      <CapacitySearchContainer>
        <ToolbarContainer>
          <StyledInput
            placeholder="Search by MC#, DOT#, Company, or Contact"
            height="36px"
            width="350px"
            clearSearch={clearValue}
            value={value}
            onChange={(e) => setValue((e.target as HTMLInputElement).value)}
          />
          <Button
            width="64px"
            height="36px"
            isRounded={true}
            disabled={isDisabled}
            onClick={onClick}
          >
            <Icon icon="search" color="white" />
          </Button>
        </ToolbarContainer>
        {resultsState === 'initialView' ? (
          <TextContainer>
            <ViewText>Search for a carrier</ViewText>
          </TextContainer>
        ) : (
          <CarrierSearchResults />
        )}
        <Snackbar
          duration={1000000}
          message="Enter a minimum of 3 characters to search"
          open={showSnackbar}
          variant="error"
        />
      </CapacitySearchContainer>
    </Container>
  );
};

export default Carriers;
