import { SecondaryNavigation } from 'shamrock-clover-ui';
import styled from 'styled-components';

export const DrawerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    font-size: 24px;
    margin: 0;
  }
  padding: 16px 24px 16px 40px;
`;

export const DrawHeader = styled.div`
  display: flex;
  padding: 0 24px 0 40px;
  justify-content: space-between;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #999999;
  align-items: center;
  height: 76px;
  > span {
    color: #fff;
  }
`;

export const ContentContainer = styled.div`
  padding: 16px 24px 16px 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 78px;
  overflow-y: auto;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  > span {
    font-size: 14px;
  }
`;

export const PaginationButton = styled.div`
  height: 41px;
  width: 41px;
  border-radius: 50%;
  background-color: #f9f9f9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    cursor: pointer;
  }
  &:hover {
    background-color: #eaf7ff;
  }
`;

export const DrawerContentNavigation = styled(SecondaryNavigation)`
  margin: 0px
  padding: 8px 0px;
  border-bottom: 0;
  height: 17px;
`;
