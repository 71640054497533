import {
  DrawerTitle,
  DrawHeader,
} from './DrawerStyles';
import React, { FC, ReactNode } from 'react';
import { CompanyCapacityInfo } from '../../CapacityTypes'; 

export interface AddLanesDrawerHeaderProps {
    companyInfo: CompanyCapacityInfo;
    actionButtons: ReactNode;
}

const AddLanesDrawerHeader: FC<AddLanesDrawerHeaderProps> = ({ companyInfo, actionButtons }) => {
    return (
        <>
        <DrawerTitle>
          <p>Adding Lane</p>
        </DrawerTitle>
          <DrawHeader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h2
                style={{
                  fontSize: '32px',
                }}
              >
                {companyInfo?.company}
              </h2>
            </div>
            {actionButtons}
          </DrawHeader>
          </>
    );
}
 
export default AddLanesDrawerHeader;