import {
  Button,
  Icon,
  SelectInput,
  SmrTable,
  styled,
  TextInput,
  Drawer,
} from 'shamrock-clover-ui';
import CapacityScoreChip from './CapacityScoreChip';
import { useContext, useState } from 'react';
import { SortOrder, Column, Row } from 'components/cloverTable/TableTypes';
import { CarrierCapacityContext } from '../context/CarrierCapacityContext';
import {
  CompanyText,
  StyledCheckmark,
  TableContainer,
} from '../CarrierCapacityStyles';
import CarrierProfileDrawerHeader from './Drawer/CarrierProfileDrawerHeader';
import DrawerSubheader from './Drawer/DrawerSubheader';
import { CompanyCapacityInfo, CarrierProfileTab } from '../CapacityTypes';
import CompanyDetails from './Drawer/CompanyDetails';
import DetailsTable from './Drawer/DetailsTable';
import AddLanesDrawerHeader from './Drawer/AddLanesDrawerHeader';
import { CarrierLoadHistory } from './Drawer/CarrierLoadHistory';
import { ContentContainer } from './Drawer/DrawerStyles';

const StyledTextContainer = styled.div`
  justify-content: center;
  height: 70px;
  display: flex;
  align-items: center;
`;

const AddLaneForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const AddLanesFormContent = styled.div`
  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100vh;
    background: #999999;
    right: 49%;
    top: 154px;
  }
`;

const AddLaneSelect = styled(TextInput)`
  height: 55px;
`;

const InputsTitles = styled.span`
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px;
`;

const ResultText = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  text-align: center;
`;

const ActionButtons = () => (
  <div style={{ display: 'flex', gap: 20 }}>
    <Button buttonStyle="outlined">Cancel</Button>
    <Button buttonStyle="solid" color="green">
      Add Lane
    </Button>
  </div>
);

const SearchCapacityResults = () => {
  const [openAddLane, setOpenAddLane] = useState(false);
  const handleRowClick = (index: number) => {
    setIsOpen(true);
    setSelectedRowIndex(index);
  };
  const { results } = useContext(CarrierCapacityContext);

  const tableRows: Row[] = results.map(
    (data: CompanyCapacityInfo, index: number) => ({
      cells: [
        {
          content: <CapacityScoreChip score={data.score} />,
          value: data.score,
        },
        {
          content: <CompanyText>{data.company}</CompanyText>,
          value: data.company,
        },
        {
          content:
            data.loadHistory === null ? '_' : data.loadHistory.toString(),
          value: data.loadHistory === null ? '_' : data.loadHistory,
        },
        {
          content: data.hq,
          value: data.hq,
        },
        {
          content: data.trucks.toString(),
          value: data.trucks,
        },
        {
          content: (
            <>
              {data.rtsprouser ? (
                <StyledCheckmark icon="checkCircleGreen" color="gray3" />
              ) : (
                <></>
              )}
            </>
          ),
          value: data.rtsprouser.toString(),
        },
        {
          content: data.mcleodId,
          value: data.mcleodId,
        },
        {
          content: (
            <>
              MC: {data.mc}
              <br />
              DOT: {data.dot}
            </>
          ),
          value: `${data.mc} ${data.dot}`,
        },
        {
          content: data.name,
          value: data.name,
        },
        {
          content: (
            <>
              {data.phone}
              <br />
              {data.email}
            </>
          ),
          value: `${data.phone} ${data.email}`,
        },
      ],
      onClick: () => handleRowClick(index),
    }),
  );
  const [isOpen, setIsOpen] = useState(false);
  const [sortedColumnIndex, setSortedColumnIndex] = useState(-1);
  const [sortDirection, setSortDirection] = useState<SortOrder>('desc');
  const [sortedTableRows, setSortedTableRows] = useState<Row[]>(tableRows);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
  const [drawerTabClicked, setDrawerTabClicked] =
    useState<CarrierProfileTab>('Details');

  const tableColumns: Column[] = [
    {
      key: 'score',
      label: 'Score',
      isSortable: true,
      width: 7,
    },
    {
      key: 'company',
      label: 'Company',
      isSortable: true,
      width: 12,
    },
    {
      key: 'loadHistory',
      label: 'Load History',
      isSortable: true,
      alignment: 'center',
      width: 9.3,
    },
    {
      key: 'hq',
      label: 'HQ',
      isSortable: false,
      alignment: 'left',
      width: 5.1,
    },
    {
      key: 'trucks',
      label: 'Trucks',
      isSortable: true,
      alignment: 'right',
      width: 6,
    },
    {
      key: 'rtsprouser',
      label: 'RTS Pro User',
      isSortable: true,
      alignment: 'center',
      width: 9.6,
    },
    {
      key: 'mcleodId',
      label: 'McLeod ID',
      isSortable: false,
      width: 9,
    },
    {
      key: 'mc/dot',
      label: 'MC/DOT',
      isSortable: false,
      width: 11,
    },
    {
      key: 'name',
      label: 'Name',
      isSortable: false,
      width: 15,
    },
    {
      key: 'contact',
      label: 'Contact',
      isSortable: false,
      width: 16,
    },
  ];

  const handleSort = (columnIndex?: number, direction?: SortOrder) => {
    if (columnIndex !== undefined && direction) {
      setSortedColumnIndex(columnIndex);
      setSortDirection(direction);
    }

    const sortedRows = [...sortedTableRows].sort((a, b) => {
      const aValue =
        columnIndex !== undefined ? a.cells[columnIndex].value : '';
      const bValue =
        columnIndex !== undefined ? b.cells[columnIndex].value : '';
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      return 0;
    });
    setSortedTableRows(sortedRows);
  };

  const handleAddLaneDrawer = () => {
    setIsOpen(false);
    setOpenAddLane(true);
  };

  return (
    <>
      {results.length > 0 && (
        <>
          <Drawer
            open={isOpen}
            onClose={() => setIsOpen(false)}
            headerSeparatorType="none"
            width={'1161'}
            overlay={true}
            drawerHeaderContent={
              <CarrierProfileDrawerHeader
                companyInfo={results[selectedRowIndex]}
                selectedIndex={selectedRowIndex}
                setSelectedIndex={setSelectedRowIndex}
                searchCapacity={true}
              />
            }
          >
            <ContentContainer>
              <DrawerSubheader setTabClicked={setDrawerTabClicked} />
              {drawerTabClicked === 'Details' && (
                <>
                  <CompanyDetails companyDetails={results[selectedRowIndex]} />
                  <DetailsTable actionHandler={handleAddLaneDrawer} />
                </>
              )}
              {drawerTabClicked === 'Load History' && <CarrierLoadHistory />}
            </ContentContainer>
          </Drawer>
          <Drawer
            open={openAddLane}
            onClose={() => setOpenAddLane(false)}
            headerSeparatorType="none"
            width={'883'}
            overlay={true}
            drawerHeaderContent={
              <AddLanesDrawerHeader
                companyInfo={results[selectedRowIndex]}
                actionButtons={<ActionButtons />}
              />
            }
          >
            <ContentContainer>
              <AddLaneForm>
                <AddLanesFormContent>
                  <span style={{ fontSize: '24px', fontWeight: 600 }}>
                    Lane Details
                  </span>
                  <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                    <InputsTitles>Pickup</InputsTitles>
                    <div
                      style={{
                        display: 'flex',
                        paddingRight: '20px',
                        gap: 3,
                      }}
                    >
                      <SelectInput
                        options={[
                          { option: 'City', optionName: 'City or Zip' },
                        ]}
                        value={{ option: 'City', optionName: 'City or Zip' }}
                        label=""
                        onOptionSelected={() => undefined}
                        width={133}
                      />

                      <TextInput label={''} variant="filled" />
                    </div>
                  </div>
                  <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                    <InputsTitles>Delivery</InputsTitles>
                    <div
                      style={{
                        display: 'flex',
                        paddingRight: '20px',
                        gap: 3,
                      }}
                    >
                      <SelectInput
                        options={[
                          { option: 'City', optionName: 'City or Zip' },
                        ]}
                        value={{ option: 'City', optionName: 'City or Zip' }}
                        label=""
                        onOptionSelected={() => undefined}
                        width={133}
                      />
                      <AddLaneSelect label={''} variant="filled" />
                    </div>
                  </div>
                  <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                    <InputsTitles>Equipment</InputsTitles>
                    <div
                      style={{
                        display: 'flex',
                        paddingRight: '20px',
                        gap: 3,
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          borderTopRightRadius: '5px',
                          borderTopLeftRadius: '5px',
                        }}
                      >
                        <SelectInput
                          options={[{ option: 'Van', optionName: 'Van' }]}
                          value={{ option: 'Van', optionName: 'Van' }}
                          label="Trailers"
                          onOptionSelected={() => undefined}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                    <InputsTitles>Manage Capacity</InputsTitles>
                  </div>
                </AddLanesFormContent>
                <div style={{ paddingLeft: '20px' }}>
                  <span style={{ fontSize: '24px', fontWeight: 600 }}>
                    Capacity Preferences
                  </span>
                  <p style={{ fontWeight: 400, fontSize: '16px' }}>
                    Add additional details about this carrier’s lane, such as
                    trucks quantity, frequency, rate and load details.
                  </p>
                  <Button
                    icon={<Icon icon="plus" color="blue" size="16" />}
                    buttonStyle="outlined"
                    isRounded={true}
                  >
                    Add Capacity
                  </Button>
                </div>
              </AddLaneForm>
            </ContentContainer>
          </Drawer>
        </>
      )}

      <StyledTextContainer>
        <ResultText>{results.length} Matching Carriers</ResultText>
      </StyledTextContainer>
      <TableContainer>
        <SmrTable
          columns={tableColumns}
          rows={sortedTableRows}
          footerOptions={{
            page: 1,
            rowsPerPageSelector: true,
            totalRowsDisplay: 'rows',
            customRowsPerPageOptions: [10, 25, 50, 75, 100],
          }}
          sortingOptions={{
            onSort: handleSort,
            sortedColumnIndex: sortedColumnIndex,
            sortDirection: sortDirection,
          }}
        />
      </TableContainer>
    </>
  );
};

export default SearchCapacityResults;
